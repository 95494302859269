<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-frequently-asked-questions</v-icon>
        Solicitar Serviço
      </v-card-title>
      <v-divider></v-divider>

      <v-tabs fixed-tabs v-model="tab">
        <v-tab>Novo</v-tab>
        <v-tab>Enviadas</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-form ref="form" v-model="validForm" lazy-validation>
            <v-textarea
              outlined
              class="mx-5"
              placeholder="Insira aqui a solicitação..."
              auto-grow
              required
              :rules="formRules"
              v-model="solicitarServico.solicitacao"
            ></v-textarea>
          </v-form>
          <v-card outlined class="mx-5 mb-5">
            <v-file-input
              suffix="Max 100MB"
              messages="Formatos permetidos : .jpg, .png, .txt, .xls, .xlsx, .pdf, .doc, .docx, .odt."
              v-model="solicitarServico.files"
              placeholder="Anexos"
              accept=".jpg,.png,.txt,.xls,.xlsx,.pdf,.doc,.docx,.odt"
              multiple
              class="mb-2 mx-2"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  small
                  label
                  close
                  close-icon="mdi-delete"
                  color="primary"
                  @click:close="remove(text)"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-card>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="createSolicitacaoServicoCliente"
              :disabled="!validForm"
              color="secondary"
              class="white--text"
            >
              enviar <v-icon right>mdi-send-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <v-tab-item><EnviadasSolicitacoesServicos :key="tab" /> </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { postSolicitacaoServico } from "@/api/cliente/solicitacoes_servicos.js";
import { mapState } from "vuex";
export default {
  name: "SolicitacoesServicos",

  data() {
    return {
      tab: 0,
      solicitarServico: {
        status: 0,
      },
      servicosEnviados: [],
      file: null,
      validForm: true,
      loading: false,
      formRules: [inputRequired],
    };
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },

  components: {
    EnviadasSolicitacoesServicos: () =>
      import("./components/EnviadasSolicitacoesServicos.vue"),
  },

  methods: {
    remove(item) {
      this.solicitarServico.files.splice(
        this.solicitarServico.files.findIndex((file) => file.name === item),
        1
      );
    },

    createSolicitacaoServicoCliente() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let block = false;

        if (this.solicitarServico.files) {
          this.solicitarServico.files.forEach((file) => {
            if (file.size >= 104857600) {
              this.$toast.error(
                "O tamanho maximo do arquivo permitido é 100MB"
              );
              block = true;
              return;
            } else if (
              file.type.substr(-5) != "/jpeg" &&
              file.type.substr(-4) != "/png" &&
              file.type.substr(-4) != "/txt" &&
              file.type.substr(-4) != "/xls" &&
              file.type.substr(-6) != ".sheet" &&
              file.type.substr(-4) != "/pdf" &&
              file.type.substr(-4) != "/doc" &&
              file.type.substr(-5) != ".text" &&
              file.type.substr(-9) != ".document"
            ) {
              this.$toast.error("Formato não permitido");
              block = true;
              return;
            }
          });
        }

        if (block === false) {
          this.loading = true;
          const solicitarServico = new FormData();
          solicitarServico.append("user_id", this.user_id);
          solicitarServico.append(
            "solicitacao",
            this.solicitarServico.solicitacao
          );
          if (this.solicitarServico.files) {
            for (var i = 0; i < this.solicitarServico.files.length; i++) {
              let file = this.solicitarServico.files[i];

              solicitarServico.append("files[" + i + "]", file);
            }
          }

          postSolicitacaoServico(solicitarServico)
            .then((response) => {
              if (response.status === 201) {
                this.$toast.success(
                  "Solicitação do serviço enviado com sucesso!"
                );
                this.solicitarServico = {};
                this.loading = false;
                this.tab = 1;
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.solicitarServico.files = null;
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style></style>
