import api from "../axios_service.js";

export async function fetchSolicitacoesServicos(filtros) {
  const response = await api.get(
    `/cliente/solicitacoes-servicos${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchSolicitacaoServico(id) {
  const response = await api.get(`/cliente/solicitacoes-servicos/${id}`);
  return response.data.result;
}

export async function postSolicitacaoServico(item) {
  const response = await api.post("/cliente/solicitacoes-servicos/add", item);
  return response;
}
